export default Object.freeze({
  /**
   * initial state
   */
  unset: 'unset',
  /**
   * Induction document directly from the client. Aka Job Safety Analysis (JSA)
   */
  induction: 'induction',
  /**
   * Occupational Health & Safety (aka WHS) policy
   */
  ohs: 'ohs',
  /**
   * COVID 19 policy document
   */
  covid: 'covid',
  handbook: 'handbook',
  extraDocument: 'extraDocument',
})
